<template>
  <div
    class="full-height"
  >
    <m-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      :project-id="projectId"
      :projects="projects"
      :nav-items="projectNavItems"
      :drawer="drawer"
      right
      @input="onChange"
    />

    <m-loader-circular
      v-if="!projectId || $wait.is('loading project')"
    />

    <router-view
      v-else
    />

    <v-bottom-navigation
      v-if="$vuetify.breakpoint.mobile"
      grow
      app
      color="accent"
    >
      <v-btn
        v-for="(nav, i) in mobileShortcuts"
        :key="i"
        min-width="60px"
        :to="nav.to"
      >
        <span
          style="margin-top: 2px;"
        >
          {{ nav.shortTitle || nav.title }}
        </span>

        <v-icon>
          {{ nav.icon }}
        </v-icon>
      </v-btn>

      <v-btn
        min-width="60px"
        @click="drawer = true"
      >
        <v-badge
          :value="unseenLogs.length > 0
            && $currentUser.permissions.includes('write_notification_logs')"
          top
          right
          color="deep-orange"
          :content="unseenLogs.length"
          offset-x="24"
          offset-y="14"
        >
          <div
            class="d-flex flex-column"
          >
            <v-icon>
              menu
            </v-icon>

            <span
              v-translate
              style="margin-top: 2px;"
            >
              Menu
            </span>
          </div>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapGetters } from 'vuex';
  import MNavigationDrawer from '@/components/MNavigationDrawer';

  export default {
    components: {
      MNavigationDrawer,
    },
    props: {
      projectId: {
        type: [Number, String],
        required: true,
      },
    },

    data: () => ({
      drawer: null,
    }),

    computed: {
      ...mapGetters({
        projects: 'projects/projects',
        projectNavItems: 'nav/projectNavItems',
        unseenLogs: 'notificationLogs/unseenLogs',
      }),

      mobileShortcuts() {
        return this.projectNavItems.reduce((acc, nav) => ([
          ...acc,
          ...nav.children().filter(child => child.shortcut),
        ]), []);
      },
    },

    watch: {
      projectId: {
        immediate: true,
        handler(newProjectId) {
          if (!newProjectId) return;
          this.loadProject({ id: newProjectId });
        },
      },
    },

    methods: {
      ...mapWaitingActions('project/project', {
        loadProject: 'loading project',
      }),

      onChange(state) {
        this.drawer = state;
      },
    },
  };
</script>
